.layout__wrapper {
  margin: 0;
}

.layout__header {
  margin: 0 0 0;
  padding: 0.7em 1.3em 0;
  border-bottom: solid 2px #231069;
}

.layout__logo {
  height: 40px;
}

@media all and (min-width: 600px) {
  .layout__logo {
    height: 70px;
  }  
}


.layout__header a {
  text-decoration: none;
}

.layout__mainwrap {
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
  position: relative; /* scope inner position rules */
}

.layout__footer { 
  padding: 1em 1.5em 3em;
  color: #e5deff;
  font-size: 0.8em;
  font-weight: 600;
}

@media all and (min-width: 700px) {
  .layout__main {   
    padding: 2em 6em;
    min-height: 700px;
  }
}