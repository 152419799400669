
.copyLink__invite {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 0 0 2em;
}

.copyLink__invite:hover {
  border-color: #5945ba;

}