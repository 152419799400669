.rangeInput {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;  
  background-color: #d3d3d3;
  outline: none;
  -webkit-transition: .2s;
  transition: background-color .2s;
}

.rangeInput:hover {
  background-color: #c8c8c8;
}


.rangeInput::-webkit-slider-thumb, 
.rangeInput::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%; 
  border-width: 0;
  background: #5945ba;
  cursor: pointer;
}