.userProfile__warning {
  padding: 0 15px 10px;
  margin: 0 0 3em;
  border: solid 1px #c0c0c0;
  border-left: solid 4px #ad2626;

}

.userProfile__warning h3 {
  margin-top: 0.5em;
}

.userProfile__warning .button {
  margin: 0.5em 0;
}

.userProfile__close {
  float: right;
}
