.synchronisedVideoSender {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100px;
  height: 56px;
  overflow: hidden;
}

.synchronisedVideoSender__video {
  width: 100px;
  -webkit-transform: scaleX(-1) translateY(-50%);
  transform: scaleX(-1) translateY(-50%);
  position: absolute;
  top: 50%;
  display:block;
}

@media all and (min-width:600px ) {
  .synchronisedVideoSender__video {
    width:200px;
  }

  .synchronisedVideoSender {
    width: 200px;
    height: 112px;
  }
}