.sessions__grid {
  border-collapse: collapse;
  margin-bottom: 1.5em;
}
.sessions__grid th {
  color: #212561;
  font-weight: bold;
}

.sessions__grid td, .sessions__grid th {
  padding: 10px;
}

.sessions__grid tbody {
  border-bottom: solid 1px #212561;
}

.sessions__actions a, .sessions__actions .button--link {
  margin-left: 1em;
}