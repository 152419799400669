
.durufleForm {
  max-width: 350px;
  position: relative;
}

.durufleForm .form__hint {
  color: #444;
  font-size: 0.8rem;
  display: block;
  margin-top: 0.5em;
}

.durufleForm label, input[type="text"], .durufleForm button {
  display: block;
}

.durufleForm input[type="text"], .durufleForm textarea {
  width:calc(100% - 32px);
  padding: 10px 15px;
  border: solid 1px #aaa;
}


.durufleForm textarea {
  resize: none;
}

.durufleForm label, .durufleForm legend {
  margin-bottom:1em;
  margin-top: 2em;
}

.durufleForm fieldset label {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 0.8rem;
}

.durufleForm fieldset label:last-child {
  margin-bottom: 0em;
}

.durufleForm fieldset {
  border: none;
  padding: 0;
}

.durufleForm input[type="checkbox"], .durufleForm input[type="radio"] {
  margin-right: 0.7em;
}