body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #5945ba;
  font-size: 0.9em;
}

@media all and (min-width: 600px) and (min-height: 400px) {
  body {
    font-size: 1em;
  }
} 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.clearfix {
  content: "";
  display: table;
  clear: both;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}


button, .button {
  padding: 10px 50px;
  margin-top: 2em;
  margin-bottom: 0.5em;
}

button, .button {
  cursor: pointer;
  display: block;
  position: relative;
  color: #5945ba;
  text-decoration: none;
  border: solid 2px #5945ba;
  border-radius: 5px;
  padding: 10px 40px;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 100ms, color 100ms;
  margin-bottom: 7px;
}

.button--inline {
  display: inline-block;
}

button:hover, .button:hover {
  color: #fff;
  background-color: #5945ba;
}

button:active, .button:active {
  top: 2px;
}

button:disabled {
  color: #777777;
  border-color: #777777;
  background-color: #ddd;
}

button:disabled:active {
  top:0;
}

.button--link {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  text-align: left;
  display: inline;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
  text-decoration: underline;
}

.button--link, a:link {
  color: #1d26ab;
}

button.button--link:hover {
  background: none;
  color: #1d26ab;
}

button.button--link:active {
  top:0;
}

.button--link:disabled {
  background: none;
  pointer-events: none;
}

h2 {
  font-size: 2em;
}


@media all and (min-width: 600px) and (min-height: 400px) {
  h2 {
    margin: 2.5em 0 1em;
  }
}

h2, h3 {
  color: #2a2a2a;
}