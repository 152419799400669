.userControls {
  display: block;
  float: right;
  margin: 8px 0 0 0;
  padding: 0;
  color: #e5deff;
}

.userControls > li {
  display: inline-block;
  margin-left: 10px;
}

.userControls > li.userControls__static {
  display: none;
}

@media all and (min-width: 500px) {
  .userControls > li.userControls__static {
    display: inline-block;
  }
}

.userControls .userControls__action {
  color: #e5deff;
  transition: color 0.2s;
  font-weight: bold;
  padding:0;
  margin-top: 0;
  margin-bottom: 0;
  text-decoration: underline;
}

.userControls .userControls__action:hover {
  color: #b1a9c9;
  
}

.userControls .userControls__action::before {
  content: "[";
  font-weight: normal;
  padding: 3px;
}


.userControls .userControls__action::after {
  content: "]";
  font-weight: normal;
  padding: 3px;
}

@media all and (min-width: 600px) {
  .userControls {
    margin: 26px 15px 0 0;
  }  
}