.videoProgress {
  position: relative;
  top: -3px
}

.videoProgress__svgwrap {
  line-height: 0;
}

.videoProgress__helpText {
  color: #5945ba;
  font-style: italic;
  transition: opacity 500ms;
  opacity: 0;
  margin: 0;
  float: right;
  font-size: 0.9em;
}

.videoProgress__helpText--active {
  opacity: 1;
}