.streamSession {
  max-width: 640px;
}

.streamSession__delete {
  color: #ad2626;
}
.streamSession__stats[title]::before {
  display: inline-block;
  content: attr(title);
}

.streamSession__stats,
.streamSession__stats>li  {
  display: inline-block;
  color: #5945ba;
  padding: 0;
  font-size: 0.9em;
  margin: 0 0 0.1em;
}

.streamSession__stats>li {
  margin-left: 1em;
}