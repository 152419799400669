.authentication {
  margin: 5em auto 0;
}

.authentication .firebaseui-card-footer {
  display: none;
}

.authentication .mdl-button--colored {
  background-color: #5945ba !important;
}

.authentication.authentication--fixedid .firebaseui-button.firebaseui-id-secondary-link {
  /* cancel button */
  display: none;
}

.authentication input[name="email"]:disabled {
  background-color: gainsboro;
  pointer-events: none;
  padding-left: 5px;
  padding-right: 5px;
}

/*
.authentication .firebaseui-id-page-sign-in .firebaseui-title {
  display:none;
}

.authentication .firebaseui-id-page-sign-in .firebaseui-card-header {
  margin-bottom: 1em;
}

.authentication .firebaseui-id-page-sign-in .firebaseui-card-header::after {
  content: "Enter your email address";
  color: rgba(0,0,0,.87);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0.5em;
}
*/