.publicSessions__list {
  margin: 2em 0;
  padding: 0;
}

.publicSessions__list > li {
  display: block;
  margin: 0 0 1em;
  padding: 1em;
  border: solid 1px #c9c9c9;
  border-left: solid 5px #5945ba;
  border-radius: 10px;
}


.publicSessions__header {
  margin: 0;
}

.publicSessions__live {
  font-size: 0.7em;
  display: block;
  margin-left: 1.5em;
  color: #e54c41;
  float:right;
}

.publicSessions__live::before {
  color: #e54c41;
  content: "\23FA";
  font-weight: normal;
  margin-right: 2px;
}

.publicSessions__host {
  margin: 0;
  font-size: 0.9em;
  color: #2a2a2a;
}

.publicSessions__contribution {
  font-size: 0.9em;
  color: green;
  font-weight: bold;
}

.publicSessions__features {
  margin: 0;
  padding: 0;
}

.publicSessions__features > li {
  display: inline-block;
  margin-right: 1em;
  font-size: 0.8em;
  color: #2a2a2a;
}

.publicSessions__cta {
  display: inline-block;
  position: relative;
  color: #5945ba !important;
  text-decoration: none;
  border: solid 2px #5945ba;
  border-radius: 5px;
  padding: 5px 40px;
  transition: background-color 100ms, color 100ms;
  margin-bottom: 7px;
}

.publicSessions__cta:hover {
  color: #fff !important;
  background-color: #5945ba;
}

.publicSessions__cta:active {
  top: 2px;
}
