.error {

  max-width: 400px; 
  margin: auto;
  margin-top: 50px;
}

.error__banner {
  margin: auto;
  color: #777777;
  text-align: center;
  font-size: 5em;
}

.error__title {
  font-size: 1em;
  font-weight: bold;
  color: #333;
}

@media all and (min-height: 800px)  {
  .error {
    margin-top: 100px;
  }
}
