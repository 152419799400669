.banner {
  padding: 0 15px 10px;
  margin: 0 0 2em;
  border: solid 1px #c0c0c0;
  border-left: solid 4px #5945ba;
}

.banner--warning {
  border-left-color: #ad2626;
}

.banner__heading {
  margin-top: 0.5em;
}

.banner .button {
  margin: 0.5em 0;
}