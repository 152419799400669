.synchronisedPlayback {
  display: grid;
  grid-template-columns: 150px auto;
}

.synchronisedPlayback > * {
  margin-bottom: 2em;
}

.synchronisedPlayback__label {
  color: #5945ba;
  font-size: 0.9em;
  font-weight:bold;
}

@media all and (min-width: 500px) {
  .synchronisedPlayback__controls {
    grid-template-columns: 200px auto;
  }
}