.home__hero {
  width: 100%;
  height: 550px;
  overflow: hidden;
}

.home__hero > img {
  height: 100%;
  filter: brightness(50%);
}

.home__herotext {
  position: absolute;
  color: #e5deff;
  top: 100px;
  left: 30px;
  width: 70%;
  font-size: 1.2em;
}

.home__cta {
  display: inline-block;
  color: #e5deff !important;
  text-decoration: none;
  border: solid 2px #e5deff;
  border-radius: 5px;
  margin-top: 3em;
  padding: 5px 40px;
  transition: background-color 200ms;
}

.home__cta:hover {
  background-color: rgba(229, 222, 255, 0.3); 
}

.home__cta:active {
  transition: none;
  background-color: rgba(229, 222, 255, 0.7)
}

.home__steps {
  margin:0;
  padding:0;
}

.home__steps > li {
  display: block;
  max-width: 300px;
  padding: 20px;
  margin: 0 auto 20px;
  background-color: #5945ba;
  border-radius: 10px;
}

.home__steps > li, .home__steps h3 {
  color: #e5deff;
}

.home__steps svg, .home__steps img {
  fill: #e5deff;
  height: 100px;
  max-width: 100px;
  display: block;
  margin: 2em auto;
}

.home__steps h3 {
  margin-top:0;
}

.home__cta2 {
  display: flex;
  align-items: center;
  margin: 3em 15px;
}

.home__cta2question {
  font-size: 1.5em;
  color: #555;
  font-style: italic;
}
.home__cta2answer {
  margin-left:1em;
  padding-left:0.5em;
  border-left: dotted 1px #555;
}

.home__cta2 .button {
  margin:0;
}

@media all and (min-width: 450px) {
  .home__cta2 {
    align-items: baseline;
  }  
}

@media all and (min-width: 500px) and (min-height: 500px) {
  .home__hero {
    height: 620px;
  }
  
  .home__herotext {
    right: 100px;
    width: 50%;
    left:inherit;
  }

  .home__cta {
    margin-top: 0;
  }
}

@media all and (min-width: 900px) { 
  .home__hero > img {
    width: 100%;
    height: initial;
  }
  
  .home__steps {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 33% 33% 33%;
  }

  .home__steps > li{
    margin: 0 15px;
  }
}

@media all and (min-width: 1000px) {
 
  .home__herotext {
    font-size: 1.5em;
  }
}

@media all and (min-width: 1000px) and (min-height: 900px) {
  .home__hero {
    height: 800px;
  }
  
  .home__herotext {
    font-size: 2em;
  }
  
  .home__hero > img {
    filter: brightness(70%);
  }
}