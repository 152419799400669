.performanceNote {
  border: 1px solid gainsboro;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px 20px 10px;
}

.performanceNote__timestamp {
  font-size: 0.7em;
  font-weight: bold;
  color: #5945ba;
  margin-bottom: 0;
}

.performanceNote__line {
  margin: 0.3em 0;
}