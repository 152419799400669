.videoTest__video {
  -webkit-transform: scaleX(-1) translateY(-50%);
  transform: scaleX(-1) translateY(-50%);
  width: 250px;
  position: absolute;
  top: 50%;
  filter: brightness(80%);
}

.videoTest__wrapper {
  width: 250px;
  height: 141px;
  display: block;
  margin:auto;
  position: relative;
  overflow: hidden;
}

.videoTest__compliment {
  width: 250px;
  text-align: center;
  color: #5945ba;
  font-weight: bold;
  margin: auto;
}

@media all and (min-width: 500 px) {
  .videoTest__video {
    width: 400px;
  }

  .videoTest__wrapper {
    width: 400px;
    height: 225px;
  }
  
}