.loading svg {
  fill: #5945ba;
  height: 20px;
  width: 20px;
}

.loading.loading--big svg {
  height: 200px;
  width: 200px;
  display: block;
  margin: 5em auto 0;
}

.loading__message {
  height: 30px;
  display: inline-block;
  margin-left: 5px;
  color: #5945ba;  
}

.loading--big .loading__message {
  height: initial;
  width: 200px;
  display: block;
  margin: 1em auto 2em;
  text-align: center;
}