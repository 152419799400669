.recorder {
  max-width: 640px;
}

.recorder__start {
  margin:0;
  padding: 30px 70px;
  position: absolute;
  pointer-events: all;
  opacity: 2;
  top: 50%;
  left: 50%;
  background-color: #f5f5f5;
  transform: translate(-50%, -50%);
}
.recorder__start:active {
  top: calc(50% + 2px);
}

.recorder__cancel,
.recorder__cancel:active, 
.recorder__cancel:hover {
  display: block;
  position: absolute;
  pointer-events: all;
  top: unset;
  bottom: 0px;
  left:0;
  background-color: gainsboro;
  border-radius: 0;
  padding: 10px 30px;
  color: #ad2626;
  border: none;
  text-decoration: underline;
}

.recorder__finish.recorder__finish--active {
  opacity: 1;
}

.recorder__videoOverlay {
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%;
  height: 100%;
}

.useVideoPlayer__playwrap--inactive .recorder__videoOverlay {
  opacity: 0.9;
  background-color: gainsboro;
}
