.dataGrid {
  width: 100%;
  border: solid 1px #C0C0C0;
  border-radius: 15px;
  background-color: #F5F5F5;
  border-spacing: 0;
  margin-top: 2em;
}

.dataGrid td {
  padding: 30px 15px;
  border-right: solid 1px #C0C0C0;
}

.dataGrid.dataGrid--compact td {
  padding: 10px 15px;
}

.dataGrid td:last-child {
  border-right: none;
}

.dataGrid tr + tr td {
  border-top: solid 1px #C0C0C0;
}

.dataGrid .button {
  margin: 0;
  display: inline-block;
  padding: 5px 40px;
  margin-bottom: 0.5em;
}

.dataGrid__row--hiddenOnMobile {
  display: none;
}

.dataGrid .dataGrid__row--actions {
  width: 1px;
}

.dataGrid__details > li {
  display: block;
}

@media all and (min-width: 800px) {
  .dataGrid__row--hiddenOnMobile {
    display: table-cell;
  }

  .dataGrid__showOnMobile {
    display: none !important;
  }

  .dataGrid .dataGrid__row--actions {
    width: 235px;
  }

  .dataGrid .button {
    margin-right: 15px;
    margin-bottom:0;
  }  
}

@media all and (min-width: 1000px) {
  
  .dataGrid__details > li {
    display: inline-block;
    margin-right: 1em;
  }

  .dataGrid__details > li:last-child {
    margin-right: 0;
  }
}

.dataGrid abbr {
  cursor: help;
}

.dataGrid__details {
  display: block;
  margin: 3px 0 0 0;
  padding: 0;
  color: #696969;
  font-size: 0.9em;
}
