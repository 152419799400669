.useVideoPlayer__playwrap {
  max-width: 640px;
  transition: opacity 700ms;
  position: relative;
  background-color: #111111;
  display: block;
}

.useVideoPlayer__playwrap--passive {
  pointer-events: none;
}

.useVideoPlayer__video {
  display: block;
}